import React, { Component } from 'react';
import Layout from "../components/layout";
import ChapterHero from '../components/chapter_hero.js';
import DecoBorder from '../components/deco_border.js';
import Anime from 'animejs/lib/anime.es.js';
import { Helmet } from "react-helmet";
import '../style/destinations.scss';
import '../style/chapters.scss';

class Destinations extends Component {

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  state = {
    isVisible: false
  }

  handleFadeIn() {
    Anime({
      targets: '#client-01, #client-02, #client-03, #client-04, #client-05',
      opacity: 1,
      translateX: -40,
      duration: 1600,
      delay: Anime.stagger(200),
      easing: 'easeInOutQuart'
    });
  }

  //trigger animations if elements are in view

  fadeInOnPageLoad() {

   // If the initial window is high enough to show elements
    let element = document.getElementById('client-list-container');

    if (element !== undefined) {
      if ((window.pageYOffset >= (window.pageYOffset + element.getBoundingClientRect().top - (.95 * window.innerHeight))) && (this.state.isVisible === false)) {
        // fade them in
        this.setState({isVisible: true});
        this.handleFadeIn();
      }
    }
  }

  // trigger animations if user scrolls to elements

  handleScroll() {
    const element = document.getElementById('client-list-container');

    if (element !== undefined) {
      if ((window.pageYOffset >= (window.pageYOffset + element.getBoundingClientRect().top - (.7 * window.innerHeight))) && (this.state.isVisible === false)) {
        this.setState({isVisible: true});
        this.handleFadeIn();
      }
    }

  }

  componentDidMount() {
    this.fadeInOnPageLoad();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.setState({isVisible: false});
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Careers</title>
          <link rel="canonical" href="https://www.tobyrichey.com/destinations" />
        </Helmet>
        <ChapterHero
          headline='Define Your Future.'
          subheader='Discover the perfect destination for you and your clients.'
          classList='chapter-hero-container'
          img={this.props.data.manhattan.childImageSharp.fluid}/>
        <DecoBorder />
        <div id='destinations-container'>
          <div id='destinations' className='chapter-content'>
            <div className='body-container'>
              <div className='text-block'>
                <div id='text'>
                  <h3>
                  Our most important relationships are with the financial advisors we represent.
                  </h3>
                  <p>
                  Toby Richey & Associates is a national pacesetter in the recruitment and placement
                  of top producing financial advisors with major Wall Street wealth management firms.
                  As independent recruiters, with a wide array of clients representing every imaginable model,
                  our dedication to unbiased candidate-centered service ensures you are in the perfect position
                  to select the firm best aligned to your goals. We are wholly dedicated to providing excellence
                  in service and strive to represent the best interests of the advisors and client firms we serve.
                  </p>
                </div>
                <div className='hor-rule'/>
                <div id='client-list-container' className='list-container'>
                  <div id='logo'/>
                  <div id='list-wrapper'>
                    <h3>
                      Best in Class Clients
                    </h3>
                    <ul id='client-list'>
                      <li id='client-01'>Wirehouses / PWM</li>
                      <li id='client-02'>Regionals</li>
                      <li id='client-03'>Independents</li>
                      <li id='client-04'>RIAs / Hybrid RIAs</li>
                      <li id='client-05'>Banks</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="svg-outer svg-outer-chapter-page">
            <svg className='svg-inner gray' width='100%' height='100%' viewBox="0 0 100 100" preserveAspectRatio="none">
             <polygon points="0,0 0,100 100,100 100,0 50,98" />
            </svg>
          </div>
        </div>
      </Layout>
    )
  }

}

export const query = graphql`
  {
    manhattan: file(relativePath: { eq:
    "manhattan.jpg" }) {
      childImageSharp {
        fluid(quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }`
  ;

export default Destinations;
